<template>
  <validation-observer
    ref="saveFormRef"
    #default="{ invalid }"
  >
    <b-modal
      id="subjectSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm môn học' : 'Cập nhật môn học'"
      size="lg"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên môn học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên môn học"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  name="name"
                  placeholder="Nhập tên môn học"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="mainName">
              <template v-slot:label>
                Tên môn học chính thức <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên môn học chính thức"
                rules="required"
              >
                <b-form-input
                  id="mainName"
                  v-model="targetItem.mainName"
                  name="mainName"
                  placeholder="Nhập tên môn học chính thức"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="department">
              <template v-slot:label>
                Khoa <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khoa"
                rules="required"
              >
                <v-select
                  v-model="targetItem.departmentId"
                  :options="departmentOptions"
                  :reduce="(option) => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="subjectType">
              <template v-slot:label>
                Loại môn học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại môn học"
                rules="required"
              >
                <v-select
                  v-model="targetItem.subjectTypeId"
                  :options="subjectTypeOptions"
                  :reduce="(option) => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã môn học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã môn học"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.code"
                  name="code"
                  placeholder="Nhập mã môn học"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="checkCodeExist"
                  class="text-danger"
                >
                  Mã môn học đã tồn tại
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="theoryCreditNum">
              <template v-slot:label>
                Số tín chỉ lý thuyết <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số tín chỉ lý thuyết"
                rules="required"
              >
                <b-form-input
                  id="theoryCreditNum"
                  v-model="targetItem.theoryCreditNum"
                  name="theoryCreditNum"
                  type="number"
                  placeholder="Nhập số tín chỉ lý thuyết"
                  :state="getElementState(errors)"
                  @input="onChangeTheoryCreditNum"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="theoryCreditNum">
              <template v-slot:label>
                Số tiết lý thuyết
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số tiết lý thuyết"
              >
                <b-form-input
                  id="numberTheory"
                  v-model="targetItem.numberTheory"
                  name="theoryCreditNum"
                  type="number"
                  placeholder="Nhập số tiết lý thuyết"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="practiceCreditNum">
              <template v-slot:label>
                Số tín chỉ thực hành <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số tín chỉ thực hành"
                rules="required"
              >
                <b-form-input
                  id="practiceCreditNum"
                  v-model="targetItem.practiceCreditNum"
                  name="practiceCreditNum"
                  type="number"
                  placeholder="Nhập số tín chỉ thực hành"
                  :state="getElementState(errors)"
                  @input="onChangePracticeCreditNum"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="numberPractice">
              <template v-slot:label>
                Số tiết thực hành
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số tiết thực hành"
              >
                <b-form-input
                  id="numberPractice"
                  v-model="targetItem.numberPractice"
                  name="practiceCreditNum"
                  type="number"
                  placeholder="Nhập số tín chỉ thực hành"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="numberExercise">
              <template v-slot:label>
                Số tiết thi
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số tiết thi"
              >
                <b-form-input
                  id="numberPractice"
                  v-model="targetItem.numberExercise"
                  name="numberExercise"
                  type="number"
                  placeholder="Nhập số tiết thi"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group
              label="Số tín chỉ thực tập"
              label-for="internshipCreditNum"
            >
              <b-form-input
                id="internshipCreditNum"
                v-model="targetItem.internshipCreditNum"
                name="internshipCreditNum"
                type="number"
                placeholder="Nhập số tín chỉ thực tập"
                @input="updateTotalCredit"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="totalCreditNum">
              <template v-slot:label>
                Tổng số tín chỉ <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tổng số tín chỉ"
                rules="required"
              >
                <b-form-input
                  id="totalCreditNum"
                  v-model="targetItem.totalCreditNum"
                  name="totalCreditNum"
                  type="number"
                  disabled
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="lessonNum">
              <template v-slot:label>
                Số tiết học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số tiết học"
                rules="required"
              >
                <b-form-input
                  id="lessonNum"
                  v-model="targetItem.lessonNum"
                  name="lessonNum"
                  type="number"
                  placeholder="Nhập số tiết học"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group
              label="Số tiết tự học"
              label-for="selfStudyPeriodNum"
            >
              <b-form-input
                id="internshipCreditNum"
                v-model="targetItem.selfStudyPeriodNum"
                name="selfStudyPeriodNum"
                type="number"
                placeholder="Nhập số tiết tự học"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group
              label="Số tiết trao đổi"
              label-for="discussionPeriodNum"
            >
              <b-form-input
                id="discussionPeriodNum"
                v-model="targetItem.discussionPeriodNum"
                name="discussionPeriodNum"
                type="number"
                placeholder="Nhập số tiết trao đổi"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group
              label="Số tiết bài tập"
              label-for="exercisePeriodNum"
            >
              <b-form-input
                id="exercisePeriodNum"
                v-model="targetItem.exercisePeriodNum"
                name="exercisePeriodNum"
                type="number"
                placeholder="Nhập số tiết bài tập"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="countedForGpa">
              <template v-slot:label>
                Tính vào điểm GPA <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tính vào điểm GPA"
                rules="required"
              >
                <v-select
                  v-model="targetItem.countedForGpa"
                  :options="yesNoOptions"
                  :reduce="(option) => option.value"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="isTheoreticalExam">
              <template v-slot:label>
                Tổ chức thi lý thuyết <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tổ chức thi lý thuyết"
                rules="required"
              >
                <v-select
                  id="isTheoreticalExam"
                  v-model="targetItem.isTheoreticalExam"
                  :options="yesNoOptions"
                  :reduce="(option) => option.value"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="isPracticeExam">
              <template v-slot:label>
                Tổ chức thi thực hành <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tổ chức thi lý thuyết"
                rules="required"
              >
                <v-select
                  id="isPracticeExam"
                  v-model="targetItem.isPracticeExam"
                  :options="yesNoOptions"
                  :reduce="(option) => option.value"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Môn học tiên quyết"
              label-for="conditionSubject"
            >
              <v-select
                v-model="targetItem.conditionId"
                multiple
                :options="subjectDropdown"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Môn học thay thế"
              label-for="alterSubject"
            >
              <v-select
                v-model="targetItem.alterId"
                multiple
                :options="subjectDropdown"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label="Môn học học trước"
              label-for="previousSubject"
            >
              <v-select
                v-model="targetItem.previousId"
                multiple
                :options="subjectDropdown"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  label="name"
                  :options="statuses"
                  :reduce="(option) => option.id"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row />
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('subjectSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'

export default {
  name: 'SubjectSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetItem: {
        name: '',
        code: '',
        trainingFormId: null,
        status: 1,
        orderNo: 0,
        alterId: null,
        lessonNum: null,
        conditionId: null,
        previousId: null,
        departmentId: null,
        countedForGpa: 1,
        subjectTypeId: null,
        totalCreditNum: null,
        theoryCreditNum: null,
        numberTheory: null,
        numberPractice: null,
        numberExercise: null,
        practiceCreditNum: null,
        internshipCreditNum: null,
        isTheoreticalExam: 1,
        isPracticeExam: 0,
        selfStudyPeriodNum: null,
        discussionPeriodNum: null,
        exercisePeriodNum: null,
      },
      filterSubject: {
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: getUser().orgId,
        departmentId: '',
        subjectTypeId: '',
        name: '',
        code: '',
        status: 1,
        sort: '',
      },
      subjectDropdown: [],
      required,
      LESSON_THEORY_CREDIT: 0,
      LESSON_PRACTICE_CREDIT: 0,
      checkCodeExist: false,
      yesNoOptions: [
        { value: 0, label: 'Không' },
        { value: 1, label: 'Có' },
      ],
      isLessonNumInput: true,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'subject/statuses',
      departments: 'subject/departments',
      subjectTypes: 'subject/subjectTypes',
      subjects: 'subject/subjects',
      systemParameters: 'parameter/dataLists',
    }),
    departmentOptions() {
      return this.departments.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    subjectTypeOptions() {
      return this.subjectTypes.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    isCreated() {
      return !this.item
    },
    codeOld() {
      return this.item.code
    },
  },
  watch: {
    // listening change data init
    // eslint-disable-next-line func-names
    'targetItem.totalCreditNum': function (newVal) {
      if (!this.isLessonNumInput) {
        this.targetItem.lessonNum = parseFloat(this.targetItem.practiceCreditNum || 0) * parseFloat(this.LESSON_PRACTICE_CREDIT)
          + parseFloat(this.targetItem.internshipCreditNum || 0) * parseFloat(this.LESSON_PRACTICE_CREDIT)
          + parseFloat(this.targetItem.theoryCreditNum || 0) * parseFloat(this.LESSON_THEORY_CREDIT)
      }
    },
    'targetItem.code': function (newVal) {
      this.checkCode(newVal)
    },
  },
  methods: {
    ...mapActions({
      createSubjectTypes: 'subject/createSubjects',
      updateSubjectTypes: 'subject/updateSubjects',
      readSubjects: 'subject/readSubjects',
      getSubjectByCode: 'subject/getSubjectByCode',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onChangeTheoryCreditNum() {
      this.isLessonNumInput = false
      this.updateTotalCredit()
      this.targetItem.isTheoreticalExam = this.targetItem.theoryCreditNum > 0 ? 1 : 0
      this.targetItem.numberTheory = this.targetItem.theoryCreditNum * this.LESSON_THEORY_CREDIT
    },
    onChangePracticeCreditNum() {
      this.isLessonNumInput = false
      this.updateTotalCredit()
      this.targetItem.isPracticeExam = this.targetItem.practiceCreditNum > 0 ? 1 : 0
      this.targetItem.numberPractice = this.targetItem.practiceCreditNum * this.LESSON_PRACTICE_CREDIT
    },
    updateTotalCredit() {
      this.isLessonNumInput = false
      this.targetItem.totalCreditNum = +this.targetItem.theoryCreditNum
          + +this.targetItem.practiceCreditNum
          + +this.targetItem.internshipCreditNum
    },

    async onShow() {
      this.LESSON_THEORY_CREDIT = this.systemParameters.find(item => item.code === 'LESSON_THEORY_CREDIT').value || 0
      this.LESSON_PRACTICE_CREDIT = this.systemParameters.find(item => item.code === 'LESSON_PRACTICE_CREDIT').value || 0

      await this.readSubjects(this.filterSubject)
      this.subjectDropdown = this.subjects.map(item => ({
        value: item.id,
        label: item.name,
      }))
      if (this.item) {
        this.targetItem = { ...this.item }
      }
    },
    onHide() {
      this.$refs.saveFormRef.reset()
      this.targetItem = {
        name: '',
        code: '',
        trainingFormId: null,
        status: 1,
        orderNo: 0,
        alterId: null,
        lessonNum: null,
        conditionId: null,
        departmentId: null,
        countedForGpa: 1,
        subjectTypeId: null,
        totalCreditNum: null,
        theoryCreditNum: null,
        practiceCreditNum: null,
        internshipCreditNum: null,
        isTheoreticalExam: 1,
        isPracticeExam: 0,
        selfStudyPeriodNum: null,
        discussionPeriodNum: null,
        exercisePeriodNum: null,
      }
      this.isLessonNumInput = true
    },
    async onSave(type = null) {
      if (!this.targetItem.orderNo) {
        this.targetItem.orderNo = 0
      }
      const valid = this.$refs.saveFormRef.validate()
      if (valid) {
        this.isLoading = true
        try {
          if (this.targetItem.internshipCreditNum === null || this.targetItem.internshipCreditNum === '') {
            this.targetItem.internshipCreditNum = 0
          }
          const response = this.isCreated
            ? await this.createSubjectTypes(this.targetItem)
            : await this.updateSubjectTypes(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('subjectSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    async checkCode(code) {
      if (!this.isCreated && code === this.codeOld) {
        this.checkCodeExist = false
      } else {
        const res = await this.getSubjectByCode({ code })
        this.checkCodeExist = res.isSuccessful
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
